interface Props {
    onSuccess: Function;
}

interface Response {
    auth: boolean;
    token: string;
}

export const Login = ({ onSuccess }: Props) => {
    const login = () => {
        const userEL = document.getElementById("username") as HTMLInputElement;
        const passEl = document.getElementById("password") as HTMLInputElement;
        const username = userEL ? userEL.value : null;
        const password = passEl ? passEl.value : null;
        fetch("https://api.workgroup.ee/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: username,
                password: password
            })
        })
            .then(response => setTokenAndRedirectIfAuthorized(response));
    };

    const setTokenAndRedirectIfAuthorized = (response: any) => {
        if (response != null && response.headers.get("Authorization")) {
            sessionStorage.setItem("workgroup.token", response.headers.get("Authorization"));
            onSuccess(true);
        }
    };

    return (
        <div>
            <table>
                <tbody>
                    <tr key='tr1'>
                        <td valign="bottom"> Admin</td>
                        <td><input id="username" /></td>
                    </tr>
                    <tr key='tr2'>
                        <td valign="bottom">Parool</td>
                        <td><input id="password" /></td>
                    </tr>
                    <tr key='tr3'>
                        <td colSpan={2}>
                            <button onClick={login}>Logi sisse</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};