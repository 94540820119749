import logo from './logo.svg';
import './App.css';
import { Login } from './Login';
import { OrdersList } from './orders/OrdersList';
import { useState } from 'react';

export const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!sessionStorage.getItem("workgroup.token"));

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} width="150" height="58" alt="" />
      </header>
      {isAuthenticated ? (
        <OrdersList setIsAuthenticated={setIsAuthenticated} />
      ) : (
          <Login onSuccess={setIsAuthenticated} />
        )}
    </div>
  );
}

export default App;
