import React, { useEffect, useState } from 'react';
import { WorkgroupOrder } from './WorkgroupOrder';
import TableViewer from 'react-js-table-with-csv-dl';

interface Props {
    setIsAuthenticated: Function;
}

export const OrdersList: React.FC<Props> = ({ setIsAuthenticated }: Props) => {
    const [orders, setOrders] = useState<WorkgroupOrder[]>([]);

    useEffect(() => {
        const authToken = sessionStorage.getItem('workgroup.token');
        const authorizastion = authToken ? authToken : '';
        fetch("https://api.workgroup.ee/", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'Authorization': authorizastion
            }
        })
            .then((res) => res.json())
            .then(data => setOrders(data))
            .catch((error) => {
                console.log('Failed to load orders', error);
                sessionStorage.removeItem('workgroup.token');
                setIsAuthenticated(false);
            });
    }, []);

    const headers = [
        "id",
        "created_at",
        "customer_name",
        "phone_number",
        "email_address",
        "service_category",
        "service_spec",
        "object_location",
        "description"
    ];

    return (
        <>
            <div className="container">
                <TableViewer
                    title="Orders"
                    content={orders}
                    headers={headers}
                    activateDownloadButton
                    minHeight={0}
                    maxHeight={20}
                    pagination={20}
                    topPagination
                    searchEnabled
                    filename="workgroup_orders.csv"
                />
            </div>
        </>
    );
};